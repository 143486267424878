import { mapGetters, mapActions } from 'vuex'

const moduleName = 'quotetemplate'

export default {
  computed: {
    ...mapGetters(moduleName, {
      hasNtarLtarItems: 'hasNtarLtarItems',
      hasNonNtarLtarItems: 'hasNonNtarLtarItems',
      quoteTemplate: 'quoteTemplate',
      quoteType: 'quoteType',
      templateBodyId: 'templateBodyId',
      templateTimesType: 'templateTimesType'
    })
  },
  methods : {
    ...mapActions(moduleName, [
      'flagNtarLtarItems',
      'flagNonNtarLtarItems',
      'saveQuoteTemplate'])
  }
}