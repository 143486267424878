<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <div class="menu-label">
        <div class="columns is-gapless">
          <div class="column">
            <span class="title is-6"
              style="white-space: nowrap">
              {{ templateName }}
            </span>
          </div>
        </div>
      </div>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.QuoteTemplateLabourView.name }"
            active-class="is-active"
            exact>
            <span class="">Labour</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.QuoteTemplateMiscView.name }"
            active-class="is-active"
            exact>
            <span class="">Misc</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.QuoteTemplateSubletView.name }"
            active-class="is-active"
            exact>
            <span class="">Sublet</span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import QuoteTemplateRoutes from './route-types'
import StoreMixin from './storeMixin'

export default {
  name: 'QuoteTemplateSideMenu',
  components: {
    AppSideMenu
  },
  mixins: [ StoreMixin ],
  computed: {
    routeTypes() {
      return QuoteTemplateRoutes
    },
    templateName() {
      return this.quoteTemplate ? this.quoteTemplate.name : null
    }
  }
}
</script>
