<template>
  <app-side-menu v-if="isLabourRoute">
    <aside class="menu"
      slot="side-menu">
      <div class="field">
        <label class="label">Labour Times</label>
        <div :class="{ 'tooltip' : hasNtarLtarItems }"
          :data-tooltip="[hasNtarLtarItems ? 'NTAR or LTAR items exist' : '']">
          <div class="select is-fullwidth">
            <select v-model="innerTemplateTimesType"
              :disabled="hasNtarLtarItems">
              <option value="null"
                disabled>Select</option>
              <option v-for="(labourTimesType, index) in labourTimesTypes"
                :key="index">
                {{ labourTimesType }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Vehicle Body Style</label>
        <div :class="{ 'tooltip' : (innerTemplateBodyId && hasItems)}"
          :data-tooltip="[(innerTemplateBodyId && hasItems) ? 'Switch after removing all labour items' : '']">
          <div class="select is-fullwidth">
            <select v-model="innerTemplateBodyId"
              :disabled="(innerTemplateBodyId && hasItems)">
              <option value="null"
                disabled>Select</option>
              <option v-for="(style, index) in bodyStyles"
                :key="index"
                :value="style.id">
                {{ style.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { LabourTimeTypes } from '@/enums'
import StoreMixin from './storeMixin'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import _cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
  name: 'QuoteTemplateSettingMenu',
  components: {
    AppSideMenu
  },
  mixins: [ StoreMixin ],
  data() {
    return {
      innerQuoteTemplate: null,
      innerTemplateBodyId: null,
      innerTemplateTimesType: null
    }
  },
  computed: {
    ...mapGetters('vehicleOptions', {
      storeBodyShapes: 'bodyShapes'
    }),
    bodyStyles() {
      return this.storeBodyShapes
    },
    labourTimesTypes() {
      if (this.hasNonNtarLtarItems) {
        return {
          NRMA: 'NRMA',
          FQT: 'FQT',
          VERO: 'VERO-NZ',
          eMTA: 'eMTA',
          WESF: 'WESF',
          RACQ: 'RACQ'
        }
      }
      return LabourTimeTypes
    },
    isLabourRoute() {
      return this.$route.meta.route == 'labour'
    },
    hasItems() {
      return this.hasNtarLtarItems || this.hasNonNtarLtarItems
    }
  },
  watch: {
    innerTemplateBodyId(val) {
      if (this.templateBodyId !== val) {
        this.innerQuoteTemplate.bodyId = val
        this.updateQuoteTemplate()
      }
    },
    innerTemplateTimesType(val) {
      if (this.templateTimesType !== val) {
        this.innerQuoteTemplate.timesType = val
        this.updateQuoteTemplate()
      }
    },
    quoteTemplate(val) {
      if (val) {
        this.innerQuoteTemplate = _cloneDeep(val)
        this.innerTemplateTimesType = _cloneDeep(val.timesType)
        this.innerTemplateBodyId = _cloneDeep(val.bodyId)
      }
    }
  },
  methods: {
    async updateQuoteTemplate() {
      await QuoteTemplateService.updateQuoteTemplate(this.innerQuoteTemplate)
      this.$emit('getQuoteTemplate')
    }
  }
}
</script>
